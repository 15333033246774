<template>
  <div>
    <div
      class="hero-image mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="hero-text">
        <h1>Tailored Nutrition</h1>
      </div>
    </div>

    <featured-in></featured-in>

    <div class="body-content mx-auto">
      <!-- Main header Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9 mx-auto pb-lg-30 text-center">
            <div class="green-header-2-text">Nutrition tailored to you</div>
            <p>
              Tailored nutrition is a vital element for you to achieve optimal
              health. People say ‘<strong>we are what we eat</strong>’, actually
              this is not completely true, we are in fact what we absorb or
              assimilate. We all have differences in how we absorb food and we
              all have different lifestyles. Tailoring nutrition to your unique
              physiology gives you the best health retreat experience for the
              short-term and the best long-term health outcomes too.
            </p>
            <p>
              <strong
                >Your goals and needs are individual and our job is to help you
                match them.</strong
              >
            </p>
            <p>
              We can review your current lifestyle and food/nutrition habits
              choices. We can work with you to create a
              <font class="darkgreen-text">
                <i>Personalised Health plan that works for you.</i>
              </font>
            </p>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Your food options Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">Your food options</div>
            <div class="row">
              <div class="col-md-7 my-auto text-center">
                <p>
                  Our retreats offer you a multitude of healthy food options
                  including raw, vegan, and Palaeolithic choices. You may also
                  have the options of soups, smoothies, juices and broths if you
                  prefer a deeper detox or maybe wish to fast.
                </p>
                <p>
                  All of our food is freshly prepared and organic wherever
                  possible. Our chefs offer food demonstrations and health
                  education workshops, these prove very popular and can support
                  you for long-term change.
                </p>
                <p>
                  You receive an entry and exit health screening with our health
                  team and they will work with you to create your tailored
                  nutrition plan for your stay and for when you leave helping
                  you to ‘Change for Good’.
                </p>
                <div class="row mt-5 text-center">
                  <div class="col">
                    <router-link
                      to="/enquiries"
                      class="btn btn-orange-gradient enquire-now-button"
                      >ENQUIRE NOW</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-5 mt-5">
                <img
                  width="800"
                  src="@/assets/images/pages/tailored-nutrition/your-food-options.jpg"
                  alt="Food options image"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Relationship with food Section -->
      <div class="container-fluid mb-5">
        <div class="row">
          <div class="col-md-9 mx-auto text-center">
            <div class="green-header-2-text">Relationship with food</div>
            <div class="orange-text mb-3" style="font-size: 2rem">
              <i
                >In the Western world our food has changed more within the last
                60 years than it has in the previous 10,000.</i
              >
            </div>
            <p class="mt-5">
              The standard daily diet today is full of foods laced with sugar,
              hydrogenated fats and salt. Food companies are very skilled at
              manufacturing these foods to reach a ‘bliss point’ where these
              foods are the most addictive they can be.
            </p>
            <p class="mb-5">
              In a ‘natural world’ we’d rarely encounter foods with sugar, fat
              and salt and would gorge them when given a chance and our bodies
              store these rare, precious ingredients. Our bodies cannot react to
              the necessary evolutionary adaptations in such a short space of
              time and the abundance of these foods underpins the explosion in
              chronic diseases like diabetes and obesity and heart disease.
            </p>
          </div>
        </div>
        <div class="row">
          <!--<div class="col-md-5 my-auto">
                <img
                  width="100%"
                  src="@/assets/images/pages/tailored-nutrition/changing-relationship-image.jpg"
                  alt="Changing relationship image"
                />
              </div>-->
          <div
            class="
              col-md-9
              my-auto
              mx-auto
              mt-2 mt-md-0
              pt-5 pt-md-2
              text-center
            "
          >
            <div class="darkgreen-text mb-3" style="font-size: 1.3rem">
              Changing your relationship with food
            </div>
            <p class>
              We will support you in understanding your food triggers, exploring
              positive healthy alternatives that you can enjoy and experimenting
              with mindfulness principles that empower you to bridge the gap
              between stimulus and response.
            </p>
            <p class>
              Forming a positive relationship with food makes the change process
              sustainable and doable for you. We work with you to find foods you
              can enjoy and also to learn that a little indulgence is realistic
              and part of a modern world so learning to give yourself permission
              for flexibility here and there and forgiving yourself is an
              important part of the change process.
            </p>
            <p class="darkgreen-text">
              Change that lasts is change that is achievable, we provide the
              supportive and nurturing environment to allow your body and mind
              to gently develop positive new choices.
            </p>

            <div class="row mt-5 text-center">
              <div class="col">
                <router-link
                  to="/enquiries"
                  class="btn btn-orange-gradient enquire-now-button"
                  >ENQUIRE NOW</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <featured-mags></featured-mags>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <!-- Selenium Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9 mx-auto text-center">
            <div class="green-header-2-text">Supplementation</div>
            <p>
              Ideally you would get everything you need for your health from
              food, particularly if you opt for organic food. Organic food is
              naturally your best option though the reality of modern soil is
              that it is depleted in many of the natural minerals that used to
              be abundant.
            </p>
            <p>
              Modern agricultural methods undermine the nutritional foundations
              of the soil in which our food grows and even organic food cannot
              compete with the soil in which our fresh vegetables and plants
              grew decades ago.
            </p>
            <p class="darkgreen-text">
              <i
                >Minerals iodine, selenium and magnesium are 3 key examples of
                this:</i
              >
            </p>
          </div>
        </div>
      </div>

      <!-- Circle Slider -->
      <div class="container my-5 circle_slider" data-slider-id="1">
        <div class="row noselect">
          <div class="col-auto mx-auto">
            <div class="mx-auto">
              <!-- large green inner circle left -->
              <div
                class="green_circle inner_circle d-none d-lg-inline-block"
                data-position="inner_left"
              >
                <div class="green_circle_inner_left_text"></div>
                <div class="green_circle_inner_arrow"></div>
              </div>
              <div class="circle_slider_mobile_elements">
                <!-- left arrow on mobiles -->
                <div class="slider-arrow d-lg-none mx-3" data-rotate="left">
                  <i class="fa fa-angle-left fa-4x" style="color: grey"></i>
                </div>
                <!-- large selected centre circle -->
                <div class="selected_circle selected_circle_blue">
                  <div
                    class="selected_circle_text selected_circle_text_blue"
                  ></div>
                  <div
                    class="selected_circle_arrow selected_circle_arrow_blue"
                  ></div>
                </div>
                <!-- right arrow on mobiles -->
                <div class="slider-arrow d-lg-none mx-3" data-rotate="right">
                  <i class="fa fa-angle-right fa-4x" style="color: grey"></i>
                </div>
              </div>
              <!-- large green inner circle right -->
              <div
                class="green_circle inner_circle d-none d-lg-inline-block"
                data-position="inner_right"
              >
                <div class="green_circle_inner_right_text"></div>
                <div class="green_circle_inner_arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-9 mx-auto">
            <!-- large selected centre circle subheader and description -->
            <div class="text-center">
              <div
                class="
                  selected_circle_subheader selected_circle_subheader_blue
                  my-3
                "
              ></div>
              <div class="selected_circle_description blue-text"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-md-9 mx-auto pb-lg-30 text-center">
            <p>
              Today’s soil contains a fraction of these minerals than the soil
              of the 1950’s and therefore it can be wise to supplement with
              tailored vitamins, herbs and minerals for optimal nutrition.
            </p>
            <p>
              In our retreats we offer our optional super-supplementation range.
              These highly-nutritional supplements include
              <font class="darkgreen-text"
                >Wheatgrass, blue/green Algae, Aloe Ferox, Spirulina, Turmeric,
                Reishi Mushroom, Maca and Mineral Blend.</font
              >
            </p>
            <p>
              Outside of the retreat our practitioners can tailor a specific
              supplement programme for you based on your individual needs. It is
              prudent not just to guzzle a multitude of supplements but
              carefully select the ones you need to help you reach optimal
              health.
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="row mb-0 mb-md-4">
              <div class="col-md-4 m-2 m-md-0">
                <img
                  class="img-fluid"
                  src="@/assets/images/pages/tailored-nutrition/supplement-1.jpg"
                  alt="Supplements 1 image"
                />
              </div>
              <div class="col-md-4 m-2 m-md-0">
                <img
                  class="img-fluid"
                  src="@/assets/images/pages/tailored-nutrition/supplement-2.jpg"
                  alt="Supplements 2 image"
                />
              </div>
              <div class="col-md-4 m-2 m-md-0">
                <img
                  class="img-fluid"
                  src="@/assets/images/pages/tailored-nutrition/supplement-3.jpg"
                  alt="Supplements 3 image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <div class="row">
          <div class="col-md-10 mx-auto text-center">
            <router-link
              to="/enquiries"
              class="btn d-inline-block btn-orange-gradient enquire-now-button"
              >ENQUIRE NOW</router-link
            >
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3">&nbsp;</div>
      <!--<hr width="80%" /> -->

      <!-- 360 Degree Approach Section -->
      <!--<div class="container-fluid">
        <div class="row">
          <div class="col-md-8 mx-auto pb-lg-30">
            <div class="green-header-2-text text-center">360° approach</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="row text-center">
              <div class="col-12 col-md-5 mx-auto mb-5">
                <img
                  width="90%"
                  src="@/assets/images/pages/tailored-nutrition/360-triangle.jpg"
                  alt="360 triangle image"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 mx-auto text-center">
            <p>Amchara’s overall mission is to empower you to total health and your personalised health plan can create the foundation for good long-term health, excellent longevity and very low risk of disease.</p>
            <p>We can support you to achieve your optimum health or ‘360 health’ by measuring your gut, hormone and genetic performance with a series of functional tests that help us to personalise your health in a detailed way.</p>
            <p>These functional tests can be for individuals with a health problem or alternatively for those who are seeking optimal health.</p>
            <p
              class="darkgreen-text font-weight-bold"
            >We offer a complimentary 15-minute mini-consultation to explore if this is a positive option for you.</p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row my-5">
          <div class="col-md-10 mx-auto text-center">
            <a href="/360-health" class="btn btn-green-gradient green-link-button">
              Find out more about our
              <br class="d-block d-md-none" />360° health approach
            </a>
          </div>
        </div>
      </div> -->

      <hr width="80%" />
      <locations></locations>
      <div class="mt-3 mb-3">&nbsp;</div>

      <trip-advisor></trip-advisor>

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />
      <team></team>
      <hr width="80%" />

      <testimonials></testimonials>

      <div class="mt-3 mb-3">&nbsp;</div>
      <!--<hr width="80%" />
      <instagram></instagram>-->
    </div>
    <!-- body-content End -->

    <newsletter-cta></newsletter-cta>
  </div>
</template>

<script>
import FeaturedIn from "./page-partials/FeaturedIn";
import FeaturedMags from "./page-partials/FeaturedMags";
import Locations from "./page-partials/Locations";
import TripAdvisor from "./page-partials/TripAdvisor";
import Testimonials from "./page-partials/Testimonials";
import Instagram from "./page-partials/Instagram";
import NewsletterCta from "./page-partials/NewsletterCta";
import Team from "./page-partials/Team";

export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        i => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        i => i.slug == this.$route.path.replace("/", "")
      ).description
      // titleTemplate: null,
    };
  },
  data() {
    return {
      bgImages: [
        require("@/assets/images/pages/tailored-nutrition/header-image.jpg")
      ]
    };
  },
  mounted() {
    var sliders = {
      slider_1: {
        inner_left: {
          title: "Iodine",
          subheader: "Iodine",
          description:
            "Iodine plays a vital role in brain development and is essential for the functioning of the thyroid gland, which controls the body's metabolism."
        },
        middle: {
          title: "Selenium",
          subheader: "Selenium",
          description:
            "Selenium is a powerful antioxidant, critical in strengthening the immune system and the creation of new cells."
        },
        inner_right: {
          title: "Magnesium",
          subheader: "Magnesium",
          description:
            "Magnesium is essential for a healthy nervous system metabolism, calcium absorption and protein metabolism."
        }
      }
    };

    $(document).ready(function() {
      // on page load
      $(".circle_slider").each(function() {
        var sliderID = $(this).data("slider-id");

        if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
          $(this)
            .find(".green_circle_outer_left_text")
            .html(sliders["slider_" + sliderID]["outer_left"]["title"]);
        }
        $(this)
          .find(".green_circle_inner_left_text")
          .html(sliders["slider_" + sliderID]["inner_left"]["title"]);
        $(this)
          .find(".selected_circle_text")
          .html(sliders["slider_" + sliderID]["middle"]["title"]);
        $(this)
          .find(".green_circle_inner_right_text")
          .html(sliders["slider_" + sliderID]["inner_right"]["title"]);
        if (sliders["slider_" + sliderID]["outer_right"] != undefined) {
          $(this)
            .find(".green_circle_outer_right_text")
            .html(sliders["slider_" + sliderID]["outer_right"]["title"]);
        }

        $(this)
          .find(".selected_circle_subheader")
          .html(sliders["slider_" + sliderID]["middle"]["subheader"]);
        $(this)
          .find(".selected_circle_description")
          .html(sliders["slider_" + sliderID]["middle"]["description"]);
      });

      // desktop event listener
      $(".green_circle").click(function() {
        var this_circle_slider = $(this).closest(".circle_slider");
        var sliderID = this_circle_slider.data("slider-id");
        var position = $(this).data("position");

        // switch the newly selected object with the previously selected object
        var previously_selected_obj = sliders["slider_" + sliderID]["middle"];
        var newly_selected_obj = sliders["slider_" + sliderID][position];
        sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        sliders["slider_" + sliderID][position] = previously_selected_obj;

        // now update the objects on screen
        $(this)
          .find(".green_circle_" + position + "_text")
          .html(previously_selected_obj.title);
        $(this_circle_slider)
          .find(".selected_circle_text")
          .html(newly_selected_obj.title);
        $(this_circle_slider)
          .find(".selected_circle_subheader")
          .html(newly_selected_obj.subheader);
        $(this_circle_slider)
          .find(".selected_circle_description")
          .html(newly_selected_obj.description);
      });

      // mobile event listener
      $(".slider-arrow").click(function() {
        var this_circle_slider = $(this).closest(".circle_slider");
        var sliderID = this_circle_slider.data("slider-id");
        var sliderRotate = $(this).data("rotate");

        // move all objects up or down one depending on whether left (down) or right (up) arrow was pressed
        if (sliderRotate == "left") {
          var newly_selected_obj = sliders["slider_" + sliderID]["inner_left"];
          if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
            // 5 circle slider
            sliders["slider_" + sliderID]["inner_left"] =
              sliders["slider_" + sliderID]["outer_left"];
            sliders["slider_" + sliderID]["outer_left"] =
              sliders["slider_" + sliderID]["outer_right"];
            sliders["slider_" + sliderID]["outer_right"] =
              sliders["slider_" + sliderID]["inner_right"];
          } else {
            // 3 circle slider
            sliders["slider_" + sliderID]["inner_left"] =
              sliders["slider_" + sliderID]["inner_right"];
          }
          sliders["slider_" + sliderID]["inner_right"] =
            sliders["slider_" + sliderID]["middle"];
          sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        } else if (sliderRotate == "right") {
          var newly_selected_obj = sliders["slider_" + sliderID]["inner_right"];
          if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
            // 5 circle slider
            sliders["slider_" + sliderID]["inner_right"] =
              sliders["slider_" + sliderID]["outer_right"];
            sliders["slider_" + sliderID]["outer_right"] =
              sliders["slider_" + sliderID]["outer_left"];
            sliders["slider_" + sliderID]["outer_left"] =
              sliders["slider_" + sliderID]["inner_left"];
          } else {
            // 3 circle slider
            sliders["slider_" + sliderID]["inner_right"] =
              sliders["slider_" + sliderID]["inner_left"];
          }
          sliders["slider_" + sliderID]["inner_left"] =
            sliders["slider_" + sliderID]["middle"];
          sliders["slider_" + sliderID]["middle"] = newly_selected_obj;
        }

        // now update the objects on screen
        // including the (hidden on mobile) green circles incase
        // they are on desktop and expand the vieport
        if (sliders["slider_" + sliderID]["outer_left"] != undefined) {
          $(this_circle_slider)
            .find(".green_circle_outer_left_text")
            .html(sliders["slider_" + sliderID]["outer_left"]["title"]);
        }
        $(this_circle_slider)
          .find(".green_circle_inner_left_text")
          .html(sliders["slider_" + sliderID]["inner_left"]["title"]);
        $(this_circle_slider)
          .find(".selected_circle_text")
          .html(sliders["slider_" + sliderID]["middle"]["title"]);
        $(this_circle_slider)
          .find(".green_circle_inner_right_text")
          .html(sliders["slider_" + sliderID]["inner_right"]["title"]);
        if (sliders["slider_" + sliderID]["outer_right"] != undefined) {
          $(this_circle_slider)
            .find(".green_circle_outer_right_text")
            .html(sliders["slider_" + sliderID]["outer_right"]["title"]);
        }

        $(this_circle_slider)
          .find(".selected_circle_subheader")
          .html(sliders["slider_" + sliderID]["middle"]["subheader"]);
        $(this_circle_slider)
          .find(".selected_circle_description")
          .html(sliders["slider_" + sliderID]["middle"]["description"]);
      });
    });
  },
  components: {
    FeaturedIn,
    FeaturedMags,
    Locations,
    TripAdvisor,
    Testimonials,
    Instagram,
    NewsletterCta,
    Team
  }
};
</script>

<style>
</style>