<template>
  <!-- Featured in Section -->
  <div class="container">
    <div class="row">
      <div class="col-md-10 mx-auto">
        <div class="green-header-2-text text-center">
          Amchara has featured in
        </div>
        <div class="row mb-2">
          <div class="col-md-10 col-xl-8 mx-auto text-center featured-in-logos">
            <img
              src="@/assets/images/logos/generic/porter-logo.png"
              width="111"
              alt="Porter logo"
            />
            <img
              src="@/assets/images/logos/generic/vogue-logo.svg"
              width="108"
              alt="Vogue logo"
            />
            <img
              src="@/assets/images/logos/generic/independant-logo.svg"
              width="201"
              alt="Independant logo"
            />
            <img
              src="@/assets/images/logos/generic/4-logo.svg"
              width="54"
              alt="4 logo"
            />
            <img
              src="@/assets/images/logos/generic/marie-claire-logo.svg"
              width="175"
              alt="Marie Claire logo"
            />
            <img
              src="@/assets/images/logos/generic/the-sunday-times-logo.svg"
              width="200"
              alt="Sunday Times logo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>