<template>
  <!-- Featured In Section -->
  <div class="container-fluid mb-3 featured-in">
    <div class="row">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-12 text-center my-auto">
            <img
              class="img-fluid featured-in-1"
              src="@/assets/images/logos/generic/conde-nast_2021.png"
              alt="Conde Nast Amchara"
            />
          </div>
          <div class="col-md-6 col-sm-12 text-center my-auto">
            <img
              class="img-fluid"
              src="@/assets/images/logos/generic/telegraph_2020.png"
              alt="The Daily Telegraph Amchara"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>