<template>
  <!-- Instagram Section -->
  <div class="container">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <div class="green-header-2-text text-center">Join the community</div>
        <div class="row mb-4">
          <div class="col-md-8 mx-auto text-center">
            <p class="green-header-3-text text-center">
              <span>
                <b>&commat;Amcharahealth</b>
              </span>
              <br />&num;changeforgood
            </p>
          </div>
        </div>
        <div class="row mb-0 mb-md-4">
          <div class="col-md-4 m-2 m-md-0">
            <img
              width="100%"
              src="@/assets/images/pages/home/instagram/insta_1.jpg"
              alt="Amchara Health Retreats Instagram @amcharahealth"
            />
          </div>
          <div class="col-md-4 m-2 m-md-0">
            <img
              width="100%"
              src="@/assets/images/pages/home/instagram/insta_2.jpg"
              alt="Amchara Health Retreats Instagram @amcharahealth"
            />
          </div>
          <div class="col-md-4 m-2 m-md-0">
            <img
              width="100%"
              src="@/assets/images/pages/home/instagram/insta_3.jpg"
              alt="Amchara Health Retreats Instagram @amcharahealth"
            />
          </div>
        </div>
        <div class="row mb-0 mb-md-4">
          <div class="col-md-4 m-2 m-md-0">
            <img
              width="100%"
              src="@/assets/images/pages/home/instagram/insta_4.jpg"
              alt="Amchara Health Retreats Instagram @amcharahealth"
            />
          </div>
          <div class="col-md-4 m-2 m-md-0">
            <img
              width="100%"
              src="@/assets/images/pages/home/instagram/insta_5.jpg"
              alt="Amchara Health Retreats Instagram @amcharahealth"
            />
          </div>
          <div class="col-md-4 m-2 m-md-0">
            <img
              width="100%"
              src="@/assets/images/pages/home/instagram/insta_6.jpg"
              alt="Amchara Health Retreats Instagram @amcharahealth"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <a
              href="http://www.instagram.com/amcharahealth"
              class="btn btn-green-gradient green-link-button m-4"
              >See more on our Instagram</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>