<template>
  <div>
    <!-- Trip Advisor Section -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 mx-auto">
          <div class="green-header-2-text text-center">Read our Trip Advisor reviews...</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8 col-lg-7 mx-auto mb-3">
          <div class="row mb-2">
            <div class="col-md-6 text-center">
              <div class="green-header-3-text text-center mb-3">
                Amchara Somerset, UK
              </div>
              <img
                src="@/assets/images/logos/generic/trip-advisor-logo-with-rating.svg"
                width="221"
                class="mb-4"
                alt="TA logo"
              />
            </div>
            <div class="col-md-6 text-center">
              <div class="green-header-3-text text-center mb-3">
                Amchara Gozo, Malta
              </div>
              <img
                src="@/assets/images/logos/generic/trip-advisor-logo-with-rating.svg"
                width="221"
                class="mb-4"
                alt="TA logo"
              />
            </div>
          </div>
        </div>
        <div class="col-md-12 mx-auto">
          <div class="row">
            <div class="col-md-4 text-center mb-5 mb-md-0">
              <div class="green-header-3-text text-center mb-3">
                Just book it, you won't be disappointed
              </div>
              <p>
                “What a wonderful place. The staff are the best, continually looking after you, offering advice and helping you to achieve your goals. The treatments are a real bonus, everything on offer is top quality and guaranteed to make you feel fantastic. Yoga in the morning and evening is the perfect way to start and end the day.”
              </p>
              <div class="green-header-5-text text-center">
                Jackie, April 2022
              </div>
              <div class="text-center">
                <img
                  src="@/assets/images/logos/generic/TA-5-circles.jpg"
                  width="91"
                  alt="Amchara Health Retreats on Trip Advisor"
                />
              </div>
            </div>
            <div class="col-md-4 text-center mb-5 mb-md-0">
              <div class="green-header-3-text text-center mb-3">A fantastic experience</div>
              <p>
                “Wonderful supportive staff. We had constant monitoring and attention. Our health was always the priority. Great accommodation. Met lovely like-minded people there as well. Yoga and walks all tailored to detoxification and wellbeing. It was the ‘pause/reset button’ I needed. Never any pressure. Protocols were personally tailored.’
              </p>
              <div class="green-header-5-text text-center">
                Maureen, May 2022
              </div>
              <div class="text-center">
                <img
                  src="@/assets/images/logos/generic/TA-5-circles.jpg"
                  width="91"
                  alt="Amchara Health Retreats on Trip Advisor"
                />
              </div>
            </div>
            <div class="col-md-4 text-center mb-5 mb-md-0">
              <div class="green-header-3-text text-center mb-3">My life-changing happy place</div>
              <p>
                “My third and definitely not final visit. I arrived with high blood pressure, overweight and exhausted. I left with excellent blood pressure, lighter and relaxed. Amchara is my happy place. Staff knowledgeable and discuss detailed personal health and lifestyle advice for your stay and beyond.”
              </p>
              <div class="green-header-5-text text-center">
                Myob, September 2021
              </div>
              <div class="text-center">
                <img
                  src="@/assets/images/logos/generic/TA-5-circles.jpg"
                  width="91"
                  alt="Amchara Health Retreats on Trip Advisor"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-0 mt-md-5">
      <div class="row">
        <div class="col-md-12 mx-auto text-center">
          <router-link
            to="/enquiries"
            class="btn d-inline-block btn-orange-gradient enquire-now-button"
            >ENQUIRE NOW</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>