<template>
  <div>
    <!-- Core team Section -->
    <div class="container-fluid mb-2">
      <div class="row">
        <div class="col-md-12 mx-auto pb-lg-30">
          <div class="green-header-2-text text-center">
            The core health team at Amchara UK
          </div>
        </div>
      </div>
      <div class="row mb-4 mb-md-5">
        <div class="col-md-12 mx-auto text-center">
          <div
            class="card d-inline-block shadow mb-4 mb-md-0"
            style="width: 14rem; margin: 4px"
          >
            <img
              class="card-img-top"
              src="@/assets/images/pages/uk/patricia.jpg"
              alt="Card image - Patricia"
            />
            <div class="card-body">
              <h6 class="card-title">Dr. Patricia Saintey</h6>
              <p class="card-text">
                Amchara Doctor
                <br />&nbsp;
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 mx-auto text-center mb-5">
          <p>
            We have a fantastic team of people at Amchara Somerset. From highly
            experienced naturopaths and health experts to executive chefs and
            qualified exercise instructors, we’re all here with one aim - to
            help you Change For Good.
          </p>
        </div>
      </div>
      <!--<div class="row mb-5">
        <div class="col-md-12 text-center">
          <a
            href="/team"
            class="btn btn-green-gradient green-link-button m-4"
          >Meet the rest of our team</a>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>